import React, { useCallback, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { captureException, captureMessage } from "@sentry/minimal"
import { useHistory } from "react-router-dom"

import {
	CustomerPartOrderPost,
	CustomerPartOrderPostSchema,
	makeApiErrorMessage,
	PaymentMethod,
	PaymentMethodBundle,
	SquareCardBrand,
	useAuth,
	useCreateCustomerPartOrder,
	useCreatePunchoutOrder,
	useIsUser,
	UserId,
	useSite,
	useUserProfile,
} from "@ncs/ncs-api"
import { formatCurrency, isCanadianProvince, noFalsy, unpythonify } from "@ncs/ts-utils"
import {
	Box,
	Card,
	Divider,
	encodeUrlState,
	ErrorText,
	Heading,
	Link,
	Paragraph,
	Price,
	ThrottledTextarea,
	useSquareCardContext,
} from "@ncs/web-legos"

import { useOrderTotals, useShopContext } from "~/contexts"
import { OrderSummaryRow, PageContentWrapper } from "~/shared-components"
import { ShopUrlState } from "~/views/shop/shop/Shop"

import { PunchOrderFinishedUrlState } from "../punchout-order-finished/PunchoutOrderFinished"
import { makeNewOrderLineItems } from "./checkout-util"
import {
	ChoosePaymentMethod,
	ChooseShippingAddress,
	ChooseShippingMethods,
	PlaceOrderButton,
} from "./components"

export const Checkout: React.FC = () => {
	const isDb = useIsUser(UserId.DrivenBrands)
	const auth = useAuth()
	const history = useHistory()

	const {
		totalItemCount,
		shipmentPartsSubtotal,
		shippingTotalWithoutAccessorials,
		shippingTotal,
		orderGrandTotal,
		totalTax,
	} = useOrderTotals()
	const [{ cart, checkout }, shopDispatch] = useShopContext()
	const { card, verifyBuyer } = useSquareCardContext()

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const createOrder = useCreateCustomerPartOrder()
	const createPunchoutOrder = useCreatePunchoutOrder()

	const [profile] = useUserProfile(auth.user?.id)
	const [shipToSite] = useSite(checkout.shipToSiteId)

	const isPunchout = !!auth.punchOutSessionId

	const partOrderPostData = useMemo(() => {
		if (!checkout.shipToSiteId && !checkout.alternateAddress) {
			return null
		}
		if (!isDb && !checkout.phone) {
			return null
		}

		const order: CustomerPartOrderPost = {
			// If you're using an alternate address, then we only need the ship to if you're billing to account.
			shipToCustomerId:
				checkout.alternateAddress ?
					checkout.selectedPaymentMethod === PaymentMethod.CUSTOMER_ACCOUNT ?
						checkout.shipToSiteId ?? undefined
					:	undefined
				:	checkout.shipToSiteId ?? undefined,
			purchaseOrderNumber: checkout.purchaseOrder,
			comment: checkout.comment,
			lineItems: makeNewOrderLineItems(
				cart,
				checkout.shipments,
				checkout.accessorials,
				shippingTotalWithoutAccessorials
			),
			sessionId: auth.punchOutSessionId,
			alternateAddress:
				checkout.alternateAddress ?
					{
						address1: checkout.alternateAddress.address1 ?? null,
						address2: checkout.alternateAddress.address2,
						city: checkout.alternateAddress.city,
						state: checkout.alternateAddress.state,
						postalcode: checkout.alternateAddress.zip,
					}
				:	undefined,
			contactPhone: checkout.phone ?? undefined,
		}

		return order
	}, [
		isDb,
		cart,
		checkout.purchaseOrder,
		checkout.accessorials,
		checkout.comment,
		checkout.shipToSiteId,
		checkout.shipments,
		shippingTotalWithoutAccessorials,
		auth.punchOutSessionId,
		checkout.alternateAddress,
		checkout.selectedPaymentMethod,
		checkout.phone,
	])

	const beginOrderPlacement = async () => {
		if (!partOrderPostData) {
			console.error("Trying to place order but order data was not complete")
			return
		}

		// Turn on isSubmitting here. Each method is responsible for turning it off later.
		setIsSubmitting(true)

		if (isPunchout) {
			void postPunchoutOrder(partOrderPostData)
			return
		}

		switch (checkout.selectedPaymentMethod) {
			case PaymentMethod.SQUARE_CARD: {
				if (!card) {
					throw new Error("Square card instance not found.")
				}

				const tokenResult = await card.tokenize()
				if (tokenResult.status === "OK") {
					if (!tokenResult.token) throw new Error("Token not found on token result")

					// Now that we've got the nonce, we need to call a separate Square endpoint
					// to verify buyer details and charge amount.
					if (!profile) throw new Error("User profile not loaded")

					const verificationResult = await verifyBuyer(tokenResult.token, {
						amount: String(orderGrandTotal),
						currencyCode: "USD",
						intent: "CHARGE",
						billingContact: {
							familyName: profile.lastName,
							givenName: profile.firstName,
							email: profile.email,
							countryCode: "US",
							city: shipToSite?.city ?? checkout.alternateAddress?.city,
							addressLines: noFalsy([
								shipToSite?.address1 ?? checkout.alternateAddress?.address1,
								shipToSite?.address2 ?? checkout.alternateAddress?.address2,
							]),
							state: shipToSite?.state ?? checkout.alternateAddress?.state,
							postalCode: shipToSite?.postalCode ?? checkout.alternateAddress?.zip,
						},
					})
					if (!verificationResult) throw new Error("Error verifying card.")

					// Now put it all together and send to server.
					const { details } = tokenResult
					if (!details?.card) throw new Error("Card details not found in token result.")

					const payment: PaymentMethodBundle = {
						method: PaymentMethod.SQUARE_CARD,
						data: {
							last4: details.card.last4,
							expMonth: details.card.expMonth,
							expYear: details.card.expYear,
							brand: details.card.brand as unknown as SquareCardBrand,
							nonce: tokenResult.token,
							verificationToken: verificationResult.token,
							billingPostalCode: tokenResult.details?.billing?.postalCode ?? null,
							digitalWalletType: "NONE", // If we ever support Google Pay or Apple Pay, this'll need to change...
						},
					}

					void postNewOrder({
						...partOrderPostData,
						payment,
					})
				} else {
					console.error("Error tokenizing")
					setIsSubmitting(false)
				}
				break
			}
			case PaymentMethod.STORED_BANK_ACCOUNT: {
				if (!checkout.selectedBankAccount?.id) {
					throw new Error(
						"Trying to check out with stored bank account, but there isn't one selected in checkout state."
					)
				}

				void postNewOrder({
					...partOrderPostData,
					payment: {
						method: PaymentMethod.STORED_BANK_ACCOUNT,
						data: checkout.selectedBankAccount.id,
					},
				})
				break
			}
			case PaymentMethod.STORED_CARD: {
				if (!checkout.selectedCard?.id) {
					throw new Error(
						"Trying to check out with stored card but there isn't one selected in checkout state."
					)
				}

				void postNewOrder({
					...partOrderPostData,
					payment: {
						method: PaymentMethod.STORED_CARD,
						data: checkout.selectedCard.id,
					},
				})
				break
			}
			case PaymentMethod.CUSTOMER_ACCOUNT: {
				void postNewOrder(partOrderPostData)
				break
			}
			default: {
				console.error("No handler for selected payment method")
			}
		}
	}

	const postNewOrder = async (orderData: CustomerPartOrderPost) => {
		try {
			setIsSubmitting(true)
			setErrorMessage(null)
			const parseResult = CustomerPartOrderPostSchema.safeParse(orderData)
			if (!parseResult.success) {
				console.warn("Order data did not pass parse check", orderData)
				captureMessage("Order data post failed parsing! Posting anyway...", {
					extra: { orderData },
				})
			}

			const response = await createOrder(orderData)
			shopDispatch({
				type: "reset checkout state",
			})
			const newOrder = unpythonify(response.data)
			history.replace({
				pathname: "/shop",
				search: encodeUrlState<ShopUrlState>({
					fromOrder: newOrder.orderId.toString(),
				}),
			})
		} catch (e) {
			setErrorMessage(makeApiErrorMessage(e))
			captureException(e)
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	const postPunchoutOrder = async (orderData: CustomerPartOrderPost) => {
		try {
			setIsSubmitting(true)
			setErrorMessage(null)
			const parseResult = CustomerPartOrderPostSchema.safeParse(orderData)
			if (!parseResult.success) {
				console.warn("Punchout order data did not pass parse check", orderData)
				captureMessage("Punchout order data post failed parsing! Posting anyway...", {
					extra: { orderData },
				})
			}
			const response = await createPunchoutOrder(orderData)
			const redirectUrl = response.data
			// (The punchout order placed page will clear out shop context local storage and logout)
			history.replace({
				pathname: "/shop/punchout-order-finished",
				search: encodeUrlState<PunchOrderFinishedUrlState>({
					redirect: redirectUrl,
				}),
			})
		} catch (e) {
			setErrorMessage(makeApiErrorMessage(e))
			captureException(e)
			setIsSubmitting(false)
		}
	}

	const updateComment = useCallback(
		(newValue: string | null) => {
			shopDispatch({
				type: "set checkout comment",
				payload: newValue || null,
			})
		},
		[shopDispatch]
	)

	const isCanada = useMemo(() => {
		return isCanadianProvince(checkout.alternateAddress?.state)
	}, [checkout.alternateAddress?.state])

	return (
		<PageContentWrapper
			title="Checkout"
			breadcrumbs={[{ name: "Shop", to: "/shop" }, { name: "Checkout" }]}
		>
			<Box mb={2}>
				<Link icon="long-arrow-left" to="/shop/cart-summary" iconPosition="leading">
					Back to cart
				</Link>
			</Box>

			<Heading variant="h1" textAlign="center" mb={4}>
				Checkout
			</Heading>

			<div css={outerContainerStyle}>
				<div>
					<div css={stepContainerStyle}>
						<Heading bold mb={1}>
							1. Shipping address
						</Heading>
						<div className="step-content">
							<ChooseShippingAddress />
						</div>
					</div>

					<div css={stepContainerStyle}>
						<Heading bold mb={1}>
							2. Payment method
						</Heading>
						<div className="step-content">
							{!isPunchout ?
								<ChoosePaymentMethod />
							:	<Paragraph color="secondary">
									Payment will be handled by the website you initiated this
									session from
								</Paragraph>
							}
						</div>
					</div>

					<div css={stepContainerStyle}>
						<Heading bold mb={1}>
							3. Shipping methods
						</Heading>
						<div className="step-content">
							<ChooseShippingMethods />
						</div>
					</div>

					{!isPunchout && (
						<Box mt={3}>
							<ThrottledTextarea
								value={checkout.comment}
								onChange={updateComment}
								label="Additional comments"
								placeholder="Any additional comments about your order..."
							/>
						</Box>
					)}
				</div>

				<div>
					<Card css={orderSummaryCardStyle}>
						{!!errorMessage && <ErrorText mb={1}>{errorMessage}</ErrorText>}
						<PlaceOrderButton
							onPlaceOrder={beginOrderPlacement}
							isLoading={isSubmitting}
							setErrorMessage={setErrorMessage}
							shipToSite={shipToSite}
						/>
						<Divider />
						<Heading bold mb={1}>
							Order Summary
						</Heading>
						<OrderSummaryRow
							label={`Subtotal (${totalItemCount} item${
								totalItemCount !== 1 ? "s" : ""
							})`}
							amount={shipmentPartsSubtotal}
						/>
						<OrderSummaryRow
							label="Taxes"
							amount={isCanada ? `${formatCurrency(totalTax)} (TBD)` : totalTax}
						/>
						<OrderSummaryRow
							label="Freight"
							amount={
								isCanada ? `${formatCurrency(shippingTotal)} (TBD)`
								: isDb ?
									`Actual`
								:	shippingTotal
							}
						/>
						<Divider />
						<Box display="flex" justifyContent="space-between">
							<Heading bold css={orderTotalStyle}>
								Order Total:
							</Heading>
							<Price
								price={isDb ? orderGrandTotal - shippingTotal : orderGrandTotal}
							/>
						</Box>
					</Card>
				</div>
			</div>
		</PageContentWrapper>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 2rem;
	${theme.breakpoints.down("sm")} {
		grid-template-columns: 1fr;
	}
`
const stepContainerStyle = (theme: Theme) => css`
	margin-top: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid #eee;
	.step-content {
		padding-left: 2rem;
		${theme.breakpoints.down("sm")} {
			padding: 0 1rem;
		}
	}
`
const orderSummaryCardStyle = (theme: Theme) => css`
	position: sticky;
	top: 1rem;
	width: 25rem;
	${theme.breakpoints.down("sm")} {
		position: relative;
		top: 0;
		width: auto;
	}
`
const orderTotalStyle = (theme: Theme) => css`
	color: ${theme.palette.primary.main};
`
