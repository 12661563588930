import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { Lookup } from "../common"
import {
	CustomerMinimal,
	CustomerWithTerritory,
	EquipmentContract,
	Portal,
	WarrantyContract,
} from "../customers"
import { TerritoryMinimal, UserMinimal } from "../security"

export enum DispatchUrlPaths {
	Api = "api",
	CTM = "ctm",
	CreateDispatch = "create_dispatch",
	Dispatch = "dispatch",
	Dispatches = "dispatches",
	CustomerView = "customer_view",
	WorkOrders = "work_orders",
	CallLogs = "call_logs",
	CreateCallLog = "create_call_log",
	Documents = "documents",
}

export type DispatchStatus = Lookup<DispatchStatusId>

export enum DispatchStatusId {
	Open = "1",
	Closed = "2",
	Completed = "3",
}

export interface DispatchMinimal {
	id: string
	dispatchNumber: string
}

export interface CustomerDispatch {
	id: string
	arrivalDuration: number | null
	billNotToExceed: number | null
	callBackNumber: string | null
	callReceivedDate: string | null
	callType: {
		id: string
		description: string
	} | null
	callerName: string | null
	closedDate: string | null
	customer: CustomerWithTerritory | null
	dispatchNumber: string
	modifiedDate: string | null
	poTicket: string | null
	priority: {
		id: string
		description: string
	} | null
	responseDuration: number | null
	status: DispatchStatus["description"] | null
	statusId: string | null
	symptoms: string | null
	total: string | null
}

export interface CustomerWorkOrderLineItem {
	id: string
	billable: boolean
	netPrice: string
	partDescription: string | null
	partId: string | null
	partNumber: string | null
	quantity: string
	reason: string | null
	subTotal: string
	tax: string
	total: string
	typeDescription: string | null
	typeId: string | null
	underWarranty: boolean
	unitPrice: string
}

export interface CustomerWorkOrder {
	id: string
	closedDate: string | null
	comments: string | null
	documents: {
		id: number
		type: number
	} | null
	lineItems: CustomerWorkOrderLineItem[]
	machines: {
		id: string
		problemDescription: string | null
		techComments: string | null
		washCounter: number | null
		model: string | null
		serialNumber: string | null
	}[]
	openDate: string | null
	waaNoSignatureReason: string | null
	waaSignatureObtained: boolean
	workorderNumber: string | null // cspell:disable-line
}

export interface CustomerDispatchDocument {
	// If the doc comes back, these fields will all be there, but the endpoint returns
	// an empty object if it does not exist, so this makes it safer.
	id?: number
	documentType?: number
	name?: string
}

export interface CustomerDispatchCallLog {
	id: string
	comments: string | null
	contactMethod: string
	contactTimestamp: string
	contactedBy: CustomerMinimal
	createdBy: CustomerMinimal
	createdDate: string
	eta: string | null
	isResolved: boolean
}

export interface CustomerDispatchPost {
	bay?: string
	customer: string
	nte?: string
	phone: string
	poNumber?: string
	priority: string
	symptoms: string
}

export interface CustomerDispatchPatch {
	comments: string | null
	statusId: string | null
}

export interface CustomerDispatchQueryParams extends SearchQueryParams, OrderingQueryParams {
	site: string | null
	callReceivedGte: string | null
	closedDateGte: string | null
	statusId: string | null
	callTypeId: string | null
}

export type CustomerDispatchReportQueryParams = Omit<
	CustomerDispatchQueryParams,
	keyof SearchQueryParams | keyof OrderingQueryParams
>

export interface Dispatch {
	id: string
	altCallBackNumber: string | null
	assignedEmployee: UserMinimal | null
	assignedEmployeeId: number | null
	billNotToExceed: string | null
	billToCustomer: {
		id: string
		customerNumber: string
		name: string
		portal: Portal | null
		portalUser: UserMinimal | null
		territory: TerritoryMinimal | null
	} | null
	callBackNumber: string | null
	callReceivedDate: string
	callType: string | null
	callTypeId: number | null
	callerName: string | null
	closedBy: string | null
	closedDate: string | null
	closedReason: string | null
	createdBy: UserMinimal | null
	createdDate: string | null
	customer: CustomerWithTerritory
	dispatchContactExists: boolean
	dispatchDocs: {
		id: string
		url: string | null
		type: 27
	}[]
	dispatchNumber: string
	erp: number | null
	invoice: {
		id: string
		invoiceNumber: string
		status: string | null
		total: string
	} | null
	isReturnCall: boolean
	modifiedBy: UserMinimal | null
	modifiedDate: string | null
	numberOfAdditionalTechs: number
	offHoursDispatch: boolean
	outOfChemicals: boolean
	poTicket: string | null
	priority: string | null
	priorityId: number
	sendEmail: boolean
	source: string | null
	status: string | null
	statusId: number | null
	symptoms: string | null
	nuisance: boolean
}

export interface DispatchQueryParams extends OrderingQueryParams, SearchQueryParams {
	callReceivedGte: string | null
	closedDateGte: string | null
	endDate: string | null
	statusId: string | null
	territory: string | null
	invoice_StatusId: string | null
	billToCustomer_PortalAssignment_PortalId: string | null
	billToCustomer_PortalAssignment_AssignedEmployeeId: string | null
	callTypeId_In: string | null
	customer: string | null
	noPortalUser: true | null
	nuisance: boolean
	dispatchContactExists: string | null
	// Hard-coding this.
	fields?: string
}

export type UpdateDispatchPatch = Partial<{
	altCallBackNumber: string | null
	assignedEmployeeId: string | null
	billNotToExceed: number | null
	billToCustomerId: string | null
	callBackNumber: string | null
	callTypeId: string
	closedReason: string | null
	isReturnCall: boolean
	outOfChemicals: boolean
	poTicket: string | null
	priorityId: string
	statusId: string
	symptoms: string | null
	nuisance: boolean
}>

export interface DispatchCallLog {
	id: string
	comments: string | null
	contactedBy: CustomerMinimal
	contactMethod: string
	contactTimestamp: string
	createdBy: CustomerMinimal
	createdDate: string
	eta: string | null
	isResolved: boolean
	customer: CustomerMinimal
}

export interface DispatchCallLogCreate {
	comments: string | null
	contactedOn: string
	estimatedTimeOfArrival: string | null
	resolvesDispatch: boolean
	dispatchId: string
}

export interface WorkOrder {
	id: string
	closedDate: string | null
	comments: string | null
	documents: {
		description: string
		id: string
		name: string
		type: number
		url: string
	}[]
	lineItems: {
		billable: boolean
		netPrice: string
		partDescription: string | null
		partId: string | null
		partNumber: string | null
		quantity: string
		reason: string | null
		subTotal: string
		tax: string
		total: string
		typeDescription: string
		typeId: number
		underWarranty: boolean
		unitPrice: string
	}[]
	machines: {
		id: string
		equipment: {
			bay: string | null
			contracts: EquipmentContract[]
			frameWarrantyExpireDate: string | null // Naive date
			installDate: string | null // Naive date
			isActive: boolean
			laborWarrantyExpireDate: string | null // Naive date
			model: string | null
			modelId: number | null
			partsWarrantyExpireDate: string | null // Naive date
			requiresWashCounts: boolean
			serialNumber: string | null
			warrantyContracts: WarrantyContract[]
		}
		model: string | null
		problemDescription: string | null
		serialNumber: string | null
		techComments: string | null
		washCounter: number
	}[]
	openDate: string
	technician: UserMinimal | null
	waaNoSignatureReason: string | null
	waaSignatureName: string | null
	waaSignatureObtained: boolean
	workorderNumber: string
}

export interface CreateDispatchPost {
	dispatch: {
		altCallBackNumber: string | null
		assignedEmployee: string | null
		bay: string | null
		billNotToExceed: number | null
		callBackNumber: string | null
		callerName: string
		callType: number // default 7
		customer: string
		isReturnCall: boolean
		outOfChemicals: boolean
		poTicket: string | null
		priority: number // default 4
		symptoms: string
	}
}

export interface CreateDispatchResponse {
	dispatch: {
		id: string
		dispatchNumber: string
		// ... plus a whoooole bunch of other shiz.
	}
}
