import React, { useState } from "react"

import { css, Theme, useTheme } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Sentry from "@sentry/react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { z } from "zod"

import { isAxiosError, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Heading,
	Icon,
	Link,
	Paragraph,
	TextInputFormField,
	useUrlState,
} from "@ncs/web-legos"

import { LogoCloud, PageContentWrapper, SupportIssueModal } from "~/shared-components"

const SignInFormSchema = z.object({
	email: z.string().min(1, "Please enter your email").email(),
	password: z.string().min(1, "Please enter your password"),
})

type SignInForm = z.infer<typeof SignInFormSchema>

export type LoginUrlState = {
	redirect: string | null
}

export const Login: React.FC = () => {
	const { palette } = useTheme()
	const [isLoading, setIsLoading] = useState(false)
	const [showSupportModal, setShowSupportModal] = useState(false)
	const history = useHistory()
	const [{ redirect }] = useUrlState<LoginUrlState>({ redirect: null })

	const { control, handleSubmit, setError } = useForm<SignInForm>({
		resolver: zodResolver(SignInFormSchema),
	})

	const { login } = useAuth()

	const onSubmit = (data: SignInForm) => {
		setIsLoading(true)

		login(data.email, data.password)
			.then(() => {
				history.push(redirect || "/")
			})
			.catch((e) => {
				setIsLoading(false)
				if (isAxiosError(e)) {
					if (e.response?.status === 401) {
						setError("password", { message: "Incorrect email/password combination" })
					} else if (e.response?.status === 403) {
						setError("password", {
							message: "Account not currently valid for use on this site",
						})
						Sentry.captureException(e)
					}
				} else {
					setError("password", {
						message: makeApiErrorMessage(e),
					})
					console.error(e)
					Sentry.captureException(e)
				}
			})
		trackEvent(TrackingEvent.SIGNIN, { email: data.email })
	}

	return (
		<PageContentWrapper title="Login">
			<AnimatedEntrance show>
				<div css={outerContainerStyle}>
					<div>
						<Heading variant="h2" bold textAlign="center" mb={2}>
							Sign in
						</Heading>
						<TextInputFormField
							control={control}
							name="email"
							label="Email"
							autoFocus
							emptyValueFallback=""
						/>
						<TextInputFormField
							control={control}
							name="password"
							label="Password"
							onReturn={handleSubmit(onSubmit)}
							type="password"
							emptyValueFallback=""
						/>

						<Button
							variant="primary-cta"
							fillContainer
							containerProps={{ my: 2 }}
							onClick={handleSubmit(onSubmit)}
							isLoading={isLoading}
						>
							Sign In
						</Button>

						<Box
							display="flex"
							flexDirection="column"
							justifyContent="flex-end"
							alignItems="flex-end"
							rowGap={1}
						>
							<Link to="/auth/forgot-password" underline>
								Forgot/change password?
							</Link>
							<Button
								icon="question-circle"
								onClick={() => setShowSupportModal(true)}
							>
								I need help
							</Button>
						</Box>
					</div>

					<div>
						<Callout
							variant="info"
							p={3}
							smProps={{ p: 2 }}
							textColor={palette.text.primary}
						>
							<Heading variant="h2" bold textAlign="center" mb={3}>
								First time here?
							</Heading>
							<Paragraph>
								Welcome! The NCS Customer Portal gives you one unified website for
								purchasing parts and managing equipment service across all of the
								NCS brands.
							</Paragraph>
							<ul css={perksListStyle}>
								<li>
									<Icon icon="globe" fontSize={1.25} />
									<span>
										Shop for equipment and chemicals from the entire NCS brand
										family
									</span>
								</li>
								<li>
									<Icon icon="user-hard-hat" fontSize={1.25} />
									<span>
										Quickly and easily request and manage service on your
										equipment
									</span>
								</li>
								<li>
									<Icon icon="comment-alt-lines" fontSize={1.25} />
									<span>
										Get customizable, automatic notifications about orders,
										updates from technicians, and much more
									</span>
								</li>
							</ul>
							<Button
								variant="secondary-cta"
								fillContainer
								onClick={() => history.push("/auth/create-account")}
							>
								Create Account
							</Button>
						</Callout>
					</div>
				</div>

				<LogoCloud mt={10} />
			</AnimatedEntrance>

			<SupportIssueModal
				isOpen={showSupportModal}
				onClose={() => setShowSupportModal(false)}
			/>
		</PageContentWrapper>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	display: flex;
	> div {
		width: 50%;
		&:first-of-type {
			padding-top: 3rem;
			padding-right: 5rem;
			margin-right: 5rem;
			border-right: 1px solid #eee;
		}
	}
	${theme.breakpoints.down("sm")} {
		display: block;
		max-width: 30rem;
		margin: auto;
		> div {
			width: auto;
			&:first-of-type {
				padding: 0 0 3rem 0;
				margin: 0 0 3rem 0;
				border-right: 0;
				border-bottom: 1px solid #eee;
			}
		}
	}
`
const perksListStyle = css`
	margin: 2.5rem 0.5rem;
	li {
		display: flex;
		align-items: flex-start;
		column-gap: 1.1rem;
		margin-bottom: 1rem;
		> svg {
			position: relative;
			top: 3px;
			opacity: 0.75;
		}
		> span {
			font-size: 1.1rem;
		}
	}
`
