import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { z } from "zod"

import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { CreateAccountPost, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Divider,
	Heading,
	Paragraph,
	RadioBooleanFormField,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

import { LogoCloud, PageContentWrapper, SupportIssueModal } from "~/shared-components"

import { CurrentCustomerHelpModal } from "./components"

const NewAccountFormSchema = z
	.object({
		firstName: z.string().min(1, "Required"),
		lastName: z.string().min(1, "Required"),
		email: z.string().email("Hmm, that doesn't look like a valid email address"),
		password: z
			.string()
			.min(6, "Must be at least 6 characters long")
			.max(100, "Cannot be more than 100 characters"),
		currentCustomer: z.boolean(),
		customerNumber: z.string().max(30).nullable(),
		invoiceNumber: z.string().max(30).nullable(),
		companyName: z.string().min(1).max(100).nullable(),
	})
	.refine(
		(values) => {
			return values.currentCustomer === false || !!values.companyName
		},
		{
			message: "Please enter a company name",
			path: ["companyName"],
		}
	)

type NewAccountForm = z.infer<typeof NewAccountFormSchema>

export const CreateAccount: FC = () => {
	const { createAccountAndLogin } = useAuth()
	const { makeErrorToast } = useToast()
	const history = useHistory()
	const [showHelpModal, setShowHelpModal] = useState(false)
	const [showSupportModal, setShowSupportModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { control, watch, handleSubmit } = useForm<NewAccountForm>({
		resolver: zodResolver(NewAccountFormSchema),
		defaultValues: {
			customerNumber: null,
			invoiceNumber: null,
			companyName: null,
		},
	})

	const onSubmit = async (formData: NewAccountForm) => {
		const newAccountPost: CreateAccountPost = {
			firstName: formData.firstName,
			lastName: formData.lastName,
			email: formData.email,
			password: formData.password,
			previousPurchase: formData.currentCustomer,
			customerNumber: formData.customerNumber,
			invoiceNumber: formData.invoiceNumber,
			companyName: formData.companyName,
		}

		try {
			setIsLoading(true)
			await createAccountAndLogin(newAccountPost)
			history.push("/auth/welcome")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
			setIsLoading(false)
		}
		trackEvent(TrackingEvent.SIGNUP, {
			email: formData.email,
			previousPurchase: formData.currentCustomer,
		})
	}

	const isCurrentCustomer = watch("currentCustomer")

	return (
		<>
			<PageContentWrapper title="Create Account">
				<AnimatedEntrance mt={2} show>
					<Box maxWidth={35} mx="auto">
						<Heading variant="h2" bold textAlign="center" mb={3}>
							Create your NCS portal account
						</Heading>
						<TextInputFormField control={control} name="email" emptyValueFallback="" />
						<TextInputFormField
							control={control}
							name="firstName"
							label="First name"
							emptyValueFallback=""
						/>
						<TextInputFormField
							control={control}
							name="lastName"
							label="Last name"
							emptyValueFallback=""
						/>
						<TextInputFormField
							control={control}
							name="password"
							label="Create password"
							type="password"
							emptyValueFallback=""
						/>
						<Paragraph mt={2} mb={0.25}>
							Have you purchased parts or services from NCS or any of our partner
							brands before?
						</Paragraph>
						<RadioBooleanFormField
							control={control}
							name="currentCustomer"
							noText="No, or I'm not sure"
						/>

						<AnimatedEntrance show={!!isCurrentCustomer}>
							<Callout p="1.5rem 2rem" mb={3} css={calloutStyle}>
								<Paragraph>
									Great! In order to speed up linking this new account with your
									existing information, please also provide your business name
									and either a customer number or an invoice number from a recent
									invoice from NCS or any one of our partner brands.
								</Paragraph>
								<TextInputFormField
									control={control}
									name="companyName"
									label="Business name"
									emptyValueFallback={null}
									my={1}
								/>
								<Box
									display="flex"
									columnGap={1}
									alignItems="center"
									xsProps={{ flexDirection: "column" }}
								>
									<TextInputFormField
										control={control}
										name="customerNumber"
										label="Customer # (optional)"
										placeholder="Customer #..."
									/>
									<Paragraph small color="secondary">
										or
									</Paragraph>
									<TextInputFormField
										control={control}
										name="invoiceNumber"
										label="Invoice # (optional)"
										placeholder="Invoice #..."
									/>
								</Box>
								<Box textAlign="right" mt={1.5}>
									<Button
										icon="info-circle"
										onClick={() => setShowHelpModal(true)}
									>
										Where do I find these?
									</Button>
								</Box>
							</Callout>
						</AnimatedEntrance>

						<Divider />

						<Button
							variant="primary-cta"
							fillContainer
							onClick={handleSubmit(onSubmit)}
							isLoading={isLoading}
						>
							Create Account
						</Button>

						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							rowGap={2}
							mt={5}
						>
							<Button
								icon="long-arrow-left"
								onClick={() => history.push("/auth/login")}
							>
								Or sign in
							</Button>
							<Button
								icon="question-circle"
								onClick={() => setShowSupportModal(true)}
							>
								I need help
							</Button>
						</Box>
					</Box>
				</AnimatedEntrance>

				<LogoCloud mt={8} />
			</PageContentWrapper>

			<CurrentCustomerHelpModal
				isOpen={showHelpModal}
				onClose={() => setShowHelpModal(false)}
			/>
			<SupportIssueModal
				isOpen={showSupportModal}
				onClose={() => setShowSupportModal(false)}
			/>
		</>
	)
}

const calloutStyle = (theme: Theme) => css`
	input {
		color: ${theme.palette.text.primary};
	}
`
