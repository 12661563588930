import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"
import { TextField } from "@material-ui/core"
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab"
import { useThrottle } from "@react-hook/throttle"
import { useHistory, useRouteMatch } from "react-router-dom"

import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { CustomerPart, initialCustomerPartQueryParams, useCustomerParts } from "@ncs/ncs-api"
import {
	Box,
	encodeUrlState,
	Icon,
	Paragraph,
	PartImage,
	TextInput,
	useChangeCallback,
	useScreenSizeMatch,
	useUrlState,
} from "@ncs/web-legos"

import { PartSearchResultsUrlState } from "~/views/shop/part-search-results"

export type ShopBarSearchUrlState = Pick<PartSearchResultsUrlState, "search">

export const ShopBarSearch: FC = () => {
	const history = useHistory()
	const [popoverOpen, setPopoverOpen] = useState(false)
	const [mobileSearchInput, setMobileSearchInput] = useState<string | null>(null)
	const screenIsTiny = useScreenSizeMatch("xs")
	const onSearchPage = useRouteMatch("/shop/search")

	const [{ search }, { updateUrlValue }] = useUrlState<ShopBarSearchUrlState>({
		search: initialCustomerPartQueryParams.search,
	})

	const [throttledSearchInput, setThrottledSearchInput] = useThrottle<string | null>(
		search,
		3,
		true
	)

	// We'll control the state of the autocomplete here so that we have access
	// to what's selected when you click the 'search' button.
	const [autocompleteValue, setAutocompleteValue] = useState<CustomerPart | string | null>(null)

	const { data: partResults, isLoading } = useCustomerParts({
		params: {
			search: throttledSearchInput,
		},
		queryConfig: {
			enabled: !!throttledSearchInput,
		},
		removeChildren: true,
	})

	const getOptionLabel = (option: CustomerPart | string | null) => {
		if (option == null) {
			return ""
		} else if (typeof option === "string") {
			return option
		} else {
			return `${option.title ?? ""} ${option.onlinePartNumber ?? ""}`
		}
	}

	const onSearch = (searchValue: CustomerPart | string | null) => {
		if (searchValue == null) {
			history.push("/shop/search")
		} else if (typeof searchValue === "string") {
			if (onSearchPage) {
				updateUrlValue("search", searchValue)
			} else {
				history.push({
					pathname: "/shop/search/",
					search: encodeUrlState<PartSearchResultsUrlState>({
						search: searchValue,
					}),
				})
			}
			trackEvent(TrackingEvent.SEARCH, { searchValue: searchValue })
		} else {
			history.push(`/shop/parts/${searchValue.id}`)
			trackEvent(TrackingEvent.GO_TO_PRODUCT, { id: searchValue.id })
			trackEvent(TrackingEvent.SEARCH, { searchValue: searchValue.description })
		}
	}

	const onChangeSelection = (
		option: CustomerPart | string | null,
		reason: AutocompleteChangeReason
	) => {
		// Update local state.
		setAutocompleteValue(option)

		// Do the appropriate action for the selection.
		if (reason !== "clear") {
			onSearch(option)
		}
	}

	// Listen for the part search param going to empty. This means user has dismissed the active
	// filter pill in the search results. We should also clear out the text from the Autocomplete
	// and mobile text input when this happens.
	useChangeCallback(search, (newSearch) => {
		if (!newSearch && !!throttledSearchInput) {
			setThrottledSearchInput(null)
			setAutocompleteValue(null)
			setMobileSearchInput(null)
		}
	})

	const placeholder = "Search all parts and products..."

	return (
		<>
			{screenIsTiny ?
				<TextInput
					value={mobileSearchInput}
					onChange={(newValue) => {
						setMobileSearchInput(newValue)
						setThrottledSearchInput(newValue)
					}}
					onReturn={() => onSearch(mobileSearchInput)}
					top={0.4}
					placeholder={placeholder}
				/>
			:	<Autocomplete
					freeSolo
					fullWidth
					options={partResults}
					open={popoverOpen}
					css={autocompleteStyle}
					onClose={() => setPopoverOpen(false)}
					getOptionLabel={getOptionLabel}
					onInputChange={(e, value) => setThrottledSearchInput(value)}
					value={autocompleteValue}
					onChange={(e, option, reason) => onChangeSelection(option, reason)}
					renderInput={(params) => (
						<TextField
							{...params}
							InputProps={{
								...params.InputProps,
								disableUnderline: true,
								placeholder,
								startAdornment: (
									<Box mt={0.1}>
										<Icon
											icon={isLoading ? "spinner-third" : "search"}
											color="gray"
											spin={isLoading}
										/>
									</Box>
								),
								endAdornment: (
									<Box mr={0.5} mt={-0.25}>
										{params.InputProps.endAdornment}
									</Box>
								),
							}}
						/>
					)}
					onOpen={() => {
						if (throttledSearchInput) {
							setPopoverOpen(true)
						}
					}}
					renderOption={(option) => {
						return (
							<Box display="flex" alignItems="center">
								<PartImage src={option.imageUrl} width={3} />
								<Box display="flex" flexDirection="column" ml={1}>
									<Paragraph>{option.title}</Paragraph>
									<Paragraph small color="secondary">
										{option.onlinePartNumber ?
											`#${option.onlinePartNumber}`
										: option.isParent ?
											"Multiple options available"
										:	""}
									</Paragraph>
								</Box>
							</Box>
						)
					}}
				/>
			}

			<button
				type="button"
				onClick={() => onSearch(throttledSearchInput)}
				css={searchButtonStyle}
			>
				Search {!screenIsTiny ? "Catalog" : ""}
			</button>
		</>
	)
}

const autocompleteStyle = css`
	background: white;
	border-radius: 0;
	.MuiAutocomplete-inputRoot {
		padding-right: 0;
		padding-top: 3px;
		padding-left: 8px;
		.MuiAutocomplete-input {
			border-radius: 0;
			padding: 7px 10px;
		}
	}
`
const searchButtonStyle = (theme: Theme) => css`
	flex: 0 0 auto;
	color: white;
	background: ${theme.palette.primary.main};
	margin-left: 0.75rem;
	font-size: 0.8rem;
	text-transform: uppercase;
	font-weight: bold;
	padding: 8px 16px 6px 16px;
	border: 2px solid white;
	border-radius: 4px;
`
